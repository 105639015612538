<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          <employee-picker v-model="table.criteria.id" style="max-width: 410px;" />
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template slot="item" slot-scope="row">
            <tr :active="row.selected">
              <td class="text-no-wrap">
                <employee-profile :employee="row.item" />
              </td>
              <td>
                {{ row.item.workEmail || "-" }}
              </td>
              <td>{{ row.item.contact.workPhobne || "-" }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-11 14:53:18
   * @modify date 2021-01-11 14:53:18
   * @desc Contact
   */
  import { EMPLOYEES } from "../query";

  export default {
    name: "Contact",
    components: {
      EmployeePicker: () => import("@/components/selectpickers/EmployeePicker")
    },
    data: vm => ({
      table: {
        loading: false,
        data: [],
        criteria: {
          id: null
        },
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "firstName",
          descending: false
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.contact.employee"),
            align: "start",
            sortable: true,
            value: "firstName",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.contact.email"),
            align: "start",
            sortable: true,
            value: "workEmail",
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.contact.phone"),
            align: "start",
            sortable: false,
            value: "contact.workPhone",
            class: ["primary--text"]
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      },
      "table.criteria": {
        handler() {
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: EMPLOYEES,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateEmployees.content || [];
              this.table.totalItems = data.filterWithPaginateEmployees.pageInfo.totalElements || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
